@font-face {
  font-family: 'Open Sans';
  src: url('OpenSans-Regular.ttf')  format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'Open Sans';
  src: url('OpenSans-Bold.ttf')  format('truetype');
  font-weight: 700;
}

:root {
  --main-color: #000;
  --main-color-alt: #fff;
  --color-alt: #eee;
  --header-height: 50px;
  --mobile-header-height: 43px;
}

* {
  font-family: 'Open Sans', sans-serif;
}

body {
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6 {
  margin: 0;
  padding: 0;
}

h1 {
  font-size: 38px;
}

h2 {
  font-size: 31px;
  margin-bottom: 0.2em;
}

p {
  line-height: 1.5em;
  font-size: 17px;
  margin: 0.3em 0;
}

div > img {
  width: 100%;
  display: block;
}

a {
  color: var(--main-color);
  text-decoration: underline;
}

a.fill {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 3;
}

.hide {
  display: none !important;
}

.fade-hide {
  opacity: 0;
  transition: opacity 0.35s ease;
}

#pre-loader {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: var(--main-color-alt);
  z-index: 999;
  pointer-events: none;
}

@media (max-width: 767.98px) {

  h1 {
    font-size: 28px;
  }

  h2 {
    font-size: 24px;
  }
}

/* cookie banner */
#cookies-eu-banner {
  position: fixed;
  bottom: 20px;
  left: 20px;
  max-width: 400px;
  z-index: 998;
  background: var(--main-color);
  padding: 15px;
  color: var(--main-color-alt);
  box-sizing: border-box;
}

#cookies-eu-banner a {
  color: var(--main-color-alt);
  text-decoration-thickness: 2px;
  font-size: 17px;
}

#cookies-eu-banner button {
  background: var(--main-color-alt);
  box-shadow: none;
  outline: none;
  border: none;
  font-size: 17px;
  padding: 3px 6px;
  cursor: pointer;
}

#cookie-text {
  margin-bottom: 20px;
}

#cookie-interaction {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  align-content: flex-start;
}

#cookie-interaction > * {
  margin-bottom: 10px;
}

#cookie-buttons {
  display: flex;
}

#cookie-buttons > button {
  margin-right: 10px;
}

#cookie-buttons > #cookies-eu-accept {
  background: var(--main-color);
  border: 3px solid var(--main-color-alt);
  color: var(--main-color-alt);
}

@media (max-width: 767.98px) {
  #cookies-eu-banner {
    width: 100%;
    max-width: 100%;
    left: 0;
    bottom: 0;
    padding: 10px 15px;
  }

  #cookie-text {
    margin-bottom: 10px;
  }
}

/* header */

.logo {
  font-size: 36px;
  text-align: center;
  font-weight: 700;
  position: relative;
  text-align: left;
}

header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-content: center;
  align-items: center;
  padding: 0 30px;
  height: var(--header-height);
  box-sizing: border-box;
  background: var(--main-color);
  color: var(--main-color-alt);
  position: fixed;
  z-index: 4;
}

header a {
  color: var(--main-color-alt);
  text-decoration: none;
  font-size: 19px;
}

#info {
  position: fixed;
  right: 30px;
  bottom: 30px;
  width: 50px;
  height: 50px;
  background: var(--main-color);
  color: var(--main-color-alt);
  z-index: 2;
  font-family: monospace;
  font-weight: 700;
  font-size: 26px;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
}

@media (max-width: 1100px) { 
  header {
    padding: 5px 15px;
    height: var(--mobile-header-height);
  }

  .logo {
    font-size: 24px;
  }
}


/* head */

.head {
  max-width: 1200px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 40px 0 30px 0;
}

.head .start-text {
  text-align: center; 
}

.head .start-text p {
  margin-top: 0.4em;
}

nav#filter-nav {
  display: flex;
  justify-content: center;
}

nav#filter-nav ul {
  display: flex;
  flex-wrap: nowrap;
  margin: 0;
  padding: 10px;
  border: 5px solid var(--main-color);
  margin: 50px 30px 30px 30px;
}

nav#filter-nav ul li {
  list-style: none;
  margin-right: 15px;
  color: var(--main-color);
  padding: 5px 6px;
  cursor: pointer;
  font-size: 17px;
  font-weight: 700;
  position: relative;
  white-space: nowrap;
}

nav#filter-nav ul li.active {
  background: var(--main-color);
  color: var(--main-color-alt);
}

nav#filter-nav ul li:last-child {
  margin: 0;
}

nav#header-nav {
  display: flex;
  align-items: center;
  justify-content: center;
}

ul#page-list {
  margin-right: 25px;
}

ul#page-list, ul#language-list {
  display: flex;
  list-style: none;
}

ul#page-list li, ul#language-list li {
  position: relative;
  margin-right: 20px;
  font-size: 19px;
}

ul#language-list li {
  margin-right: 5px;
  font-size: 16px;
  opacity: 0.6;
}

ul#language-list li.active {
  opacity: 1;
}

ul#page-list li:last-child, ul#language-list li:last-child {
  margin: 0;
}

ul#page-list li.active {
  text-decoration: underline;
  text-decoration-thickness: 2px;
  text-underline-offset: 0.3em;
}


@media (max-width: 1100px) { 
  nav#header-nav {
    position: absolute;
    width: 100%;
    height: calc(100vh - var(--mobile-header-height));
    left: 0;
    top: var(--mobile-header-height);
    z-index: 3;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    overflow: hidden;
    background: var(--main-color);
    display: none;
  }

  nav#header-nav.open {
    display: flex;
  }
  ul#page-list {
    flex-direction: column;
    justify-content: center;
    align-items: center;
    align-content: center;
    margin-right: 0;
  }

  ul#language-list {
    justify-content: center;
    margin-top: 40px;
  }

  ul#page-list.open {
    display: flex;
  }

  ul#page-list li {
    margin: 0 0 20px 0;
    padding: 0;
  }

  nav#filter-nav ul {
    overflow: scroll;
    margin-top: 30px;
  }
}


/* grid */

.grid {
  margin: 0 auto;
}

.grid-item {
  position: relative;
  width: 600px;
  box-sizing: border-box;
  margin-bottom: 20px;
}

@media (max-width: 767.98px) {
  .grid {
    width: 100% !important;
    max-width: 600px;
  }

  .grid-item {
    width: 100%;
  }
}

/* structural */
.header-buffer {
  height: 80px;
}

.buffer-small {
  height: 10px;
}

.text-center {
  text-align: center;
}

.fullscreen {
  width: 100%;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
}

.fullscreen a {
  font-weight: 600;
}

@media (max-width: 767.98px) {
  .header-buffer, .header-buffer-mobile {
    height: 70px;
  }  
}

/* split */

.split {
  max-width: 1600px;
  min-height: 100vh;
  margin: 0 auto;
  display: flex;
  padding-top: calc((var(--header-height) /2 ) + 10vh);
}

.split.centered {
  padding-top: calc(var(--header-height) / 2);
  align-items: center;
}

.split > div {
  width: 50%;
  background-position: center center;
  background-size: cover;
  overflow: visible;
}

.split > .split-image {
  height: 70vh;
}

.split > div.text {
  flex-direction: column;
  padding: 0 4%;
  box-sizing: border-box;
}

@media (max-width: 767.98px) {
  .split {
    flex-direction: column;
    height: 100%;
    padding: 0;
  }

  .split .split-image {
    margin-top: var(--mobile-header-height);
    height: 40vh;
  }

  .split > div  {
    min-height: 100%;
    width: 100%;
  }

  .split > div.text {
    padding: 40px 15px;
    height: 100%;
  }
}

/* mosaic */

.mosaic-grid > div {
  min-height: 40vh;
  background-size: cover;
  background-position: center center;
  position: relative;
}

.mosaic-text-version {
  border: 8px solid var(--main-color);
  box-sizing: border-box;
  padding: 20px;
}

.img-layover {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  //border: 8px solid var(--main-color);
  z-index: 2;
  opacity: 0;
  transition: all 0.5s ease-in-out;
  box-sizing: border-box;
}

.mosaic-grid > div:hover .img-layover, .grid-item:hover .img-layover {
  opacity: 1;
}

.img-layover-caption {
  position: absolute;
  left: 15px;
  bottom: 15px;
  background: var(--main-color);
  color: var(--main-color-alt);
  padding: 7px;
}

@media (max-width: 767.98px) {
  .img-layover {
    opacity: 1;
  }

  .img-layover-caption {
    left: 10px;
    bottom: 10px;
    font-size: 14px;
    padding: 4px;
  }
}

/* animation */

@media (min-width: 1100px) { 
  #burgerMenu {
    display: none;
  }
 }

#burgerMenu {
  width: 35px;
  height: 25px;
  position: relative;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .5s ease-in-out;
  -moz-transition: .5s ease-in-out;
  -o-transition: .5s ease-in-out;
  transition: .5s ease-in-out;
  cursor: pointer;
}

#burgerMenu span{
  display: block;
  position: absolute;
  height: 5px;
  width: 100%;
  background: var(--color-alt);
  opacity: 1;
  left: 0;
  -webkit-transform: rotate(0deg);
  -moz-transform: rotate(0deg);
  -o-transform: rotate(0deg);
  transform: rotate(0deg);
  -webkit-transition: .25s ease-in-out;
  -moz-transition: .25s ease-in-out;
  -o-transition: .25s ease-in-out;
  transition: .25s ease-in-out;
}

#burgerMenu span:nth-child(1) {
  top: 0px;
}

#burgerMenu span:nth-child(2),#burgerMenu span:nth-child(3) {
  top: 10px;
}

#burgerMenu span:nth-child(4) {
  top: 20px;
}

#burgerMenu.open span:nth-child(1) {
  top: 10px;
  width: 0%;
  left: 50%;
}

#burgerMenu.open span:nth-child(2) {
  -webkit-transform: rotate(45deg);
  -moz-transform: rotate(45deg);
  -o-transform: rotate(45deg);
  transform: rotate(45deg);
}

#burgerMenu.open span:nth-child(3) {
  -webkit-transform: rotate(-45deg);
  -moz-transform: rotate(-45deg);
  -o-transform: rotate(-45deg);
  transform: rotate(-45deg);
}

#burgerMenu.open span:nth-child(4) {
  top: 10px;
  width: 0%;
  left: 50%;
}

/* form */

form {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
}

form .honeypot {
  position: absolute;
  left: -9999px;
}

.alert.success {
  margin-top: 20px;
  font-weight: 700;
}

form .field {
  display: flex;
  flex-direction: column;
  margin-bottom: 25px;
}

form .field.inline {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
}

form .field.inline label {
  margin: 0 0 0 10px;
}

form label {
  margin-bottom: 5px;
  font-weight: 700;
}

form input, form textarea {
  background: var(--main-color-alt);
  outline: none;
  box-shadow: none;
  border: 3px solid var(--main-color);
  padding: 10px;
  margin: 0;
  font-size: 16px;
}

form textarea {
  resize: vertical;
  white-space: nowrap;
  height: 70px;
}

input[type=submit] {
  background: var(--main-color);
  color: var(--main-color-alt);
  cursor: pointer;
}

footer {
  margin: 70px 0 30px 0;
  text-align: center;
  color: var(--main-color);
  font-size: 14px;
}

footer > .divider {
  border-top: 3px solid #000;
  display: inline-block;
  width: 30px;
  margin-bottom: 10px;
}

.footer-content {
  display: flex;
  justify-content: center;
}

.footer-content > ul {
  display: flex;
  justify-content: content;
  align-items: center;
  align-content: center;
}

.footer-content > ul > li {
  list-style: none;
  margin-right: 10px;
}

.footer-content > ul > li:last-child {
  margin: 0;
}

.footer-content > ul > li > a {
  text-decoration: none;
}

@media (max-width: 767.98px) {
  footer {
    margin: 50px 0 30px 0;
  }
}